import React from 'react';
import { useSelector } from 'react-redux';

import { Event } from '@wix/ambassador-events-v1-event/types';

import { ModalV2 } from 'common/components/Modal';
import { useController } from 'common/context/controller';
import { filterEvents } from 'common/components/Event/helpers';
import { getEventPage } from 'common/components/Event/getEventPage';

import { selectDialog } from 'store/application/selectors';
import { selectEventsRestricted } from 'store/groups/selectors';

import { IControllerMethods } from 'Group/types';

import { Restricted } from './Restricted';
import { RestrictedListModal } from './RestrictedListModal';

import { st, classes } from './EventsRestrictionDialog.st.css';

export const EventsRestrictionDialog: React.FC = () => {
  const [isRestrictedListModalOpened, setIsRestrictedListModalOpened] =
    React.useState(false);

  const { application$ } = useController<IControllerMethods>();

  const dialog = useSelector(selectDialog('eventsRestriction'));
  const events = useSelector(selectEventsRestricted(dialog.groupId as string));

  const [upcoming, past] = React.useMemo(() => {
    return filterEvents(events);
  }, [events]);

  return (
    <>
      <ModalV2 isOpen={dialog.isOpen} onClose={handleClose}>
        <ModalV2.Content className={st(classes.root)}>
          <Restricted
            upcoming={upcoming}
            past={past}
            onViewEvents={viewEvents}
          />
        </ModalV2.Content>
      </ModalV2>
      <RestrictedListModal
        events={upcoming}
        isOpen={isRestrictedListModalOpened}
        onClose={closeRestrictedListModal}
      />
    </>
  );

  function handleClose() {
    application$.closeDialog('eventsRestriction');
  }

  function closeRestrictedListModal() {
    setIsRestrictedListModalOpened(false);
  }

  function viewEvents(_events: Event[]) {
    if (_events.length === 1) {
      const eventPage = getEventPage(_events[0].eventPageUrl);
      goToEventPage(eventPage);
    } else {
      setIsRestrictedListModalOpened(true);
    }
  }
};

function goToEventPage(eventPage: string | undefined) {
  if (!eventPage) {
    return;
  }
  window.open(eventPage, '_blank');
}
