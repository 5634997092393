import React from 'react';
import { useSelector } from 'react-redux';

import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { leaveGroupClick } from '@wix/bi-logger-groups/v2';

import { selectGroup } from 'store/groups/selectors';
import { selectDialog } from 'store/application/selectors';

import {
  DATA_HOOKS as DIALOG_DATA_HOOKS,
  Dialog,
} from 'common/components/Dialog';
import { useController } from 'common/context/controller';

import type { IControllerMethods } from 'Group/types';

import { GroupMembershipButton } from '../GroupMembershipButton';

import { LEAVE_GROUP_DIALOG } from './dataHooks';

export function LeaveGroupDialog() {
  const { t } = useTranslation();
  const bi = useBi();
  const { application$, group$ } = useController<IControllerMethods>();

  const dialog = useSelector(selectDialog('leaveGroup'));
  const group = useSelector(selectGroup(dialog.groupId as string));

  if (!group) {
    return null;
  }

  return (
    <Dialog
      isOpen={dialog.isOpen}
      onClose={handleClose}
      title={t('groups-web.group.actions.leave.group')}
      data-hook={LEAVE_GROUP_DIALOG}
      caption={t('groups-web.group.actions.leave.confirm', {
        groupName: group.name,
        interpolation: { escapeValue: false },
      })}
      buttons={
        <GroupMembershipButton
          bw
          groupId={dialog.groupId as string}
          onClick={handleSubmit}
          data-hook={DIALOG_DATA_HOOKS.okButton}
        >
          {t('groups-web.group.actions.leave')}
        </GroupMembershipButton>
      }
    />
  );

  function handleSubmit() {
    group$.leave(dialog.groupId as string);
    bi.report(
      leaveGroupClick({
        group_id: dialog.groupId as string,
        origin: 'leave_dialog_modal',
      }),
    );
  }

  function handleClose() {
    application$.closeDialog('leaveGroup');
  }
}

LeaveGroupDialog.displayName = 'LeaveGroupDialog';
