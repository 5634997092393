import React from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { RequestStatus } from '@wix/ambassador-social-groups-v2-group-request/types';
import { Breadcrumbs, Spinner } from 'wix-ui-tpa';

import { selectGroupBySlug, selectGroupFetchStatus } from 'store/groups';
import { useCurrentStateAndParams, useRouter } from 'common/router';

import { Header } from '../Header/Header';
import { GroupMembershipDialogs } from '../GroupMembership';
import { DATA_HOOKS, GROUP_WRAPPER } from '../dataHooks';

import { GroupTabs } from './GroupTabs';
import { ApplicationPage } from './ApplicationPage';
import { GroupNotFound, GroupRejected } from './ErrorStates';

import { classes, st } from './Group.st.css';

export function Group() {
  const router = useRouter();
  const { t } = useTranslation();
  const { isMobile, isPreview, dimensions, isRTL } = useEnvironment();
  const { params } = useCurrentStateAndParams();

  const status = useSelector(selectGroupFetchStatus(params.slug));
  const group = useSelector(selectGroupBySlug(params.slug));

  if (status.loading) {
    return (
      <div data-hook={GROUP_WRAPPER}>
        <div
          data-hook={DATA_HOOKS.root}
          dir={isRTL ? 'rtl' : undefined}
          className={st(classes.root, { mobile: isMobile }, classes.loading)}
        >
          <Spinner isCentered />
        </div>
      </div>
    );
  }

  if (status.error) {
    return (
      <div data-hook={GROUP_WRAPPER}>
        <div
          data-hook={DATA_HOOKS.root}
          dir={isRTL ? 'rtl' : undefined}
          className={st(classes.root, { mobile: isMobile })}
        >
          <GroupNotFound />
        </div>
      </div>
    );
  }

  if (group.status === RequestStatus.REJECTED) {
    return (
      <div data-hook={GROUP_WRAPPER}>
        <div
          data-hook={DATA_HOOKS.root}
          dir={isRTL ? 'rtl' : undefined}
          className={st(classes.root, { mobile: isMobile })}
        >
          <GroupRejected />
        </div>
      </div>
    );
  }

  return (
    <div
      data-hook={GROUP_WRAPPER}
      style={{ width: isPreview && isMobile ? dimensions.width : undefined }}
    >
      <div
        data-hook={DATA_HOOKS.root}
        dir={isRTL ? 'rtl' : undefined}
        className={st(classes.root, { mobile: isMobile })}
      >
        <GroupMembershipDialogs />
        <Breadcrumbs
          className={classes.breadcrumbs}
          items={[
            {
              id: 'home',
              value: t(['groups-web.breadcrumbs.home', 'Home']),
              href: router.href('home', {}, { absolute: true }),
            },
            {
              id: 'groups',
              value: t('groups-web.breadcrumbs.group-list'),
              href: router.href('groups', {}, { absolute: true }),
            },
            {
              id: 'group',
              value:
                group.name || t('groups-web.breadcrumbs.group-title-default'),
              href: router.href(
                'group.discussion.feed',
                {},
                { absolute: true },
              ),
            },
          ]}
        />
        <Header />
        <GroupTabs />
        <ApplicationPage />
      </div>
    </div>
  );
}
