import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import {
  Text,
  Dialog,
  Spinner,
  TextTypography,
  ButtonPriority,
  TextButtonPriority,
} from 'wix-ui-tpa';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';
import type { ButtonProps } from 'wix-ui-core/dist/src/components/button-next/button-next';

import { useScrollBlock } from '../../hooks';
import { Button } from '../Button';
import { BlackAndWhiteTextButton } from '../BlackAndWhiteTextButton';

import { st, classes } from './SideDrawer.st.css';

interface ISideDrawerProps extends TPAComponentProps {
  isOpen: boolean;
  title?: string;
  children: React.ReactNode;
  cancelButtonProps?: ButtonProps & { label: string };
  submitButtonProps?: ButtonProps & { label: string; loading?: boolean };
  enableScrollLock?: boolean;

  onClose(): void;
}

export const SideDrawer: React.FC<ISideDrawerProps> = (props) => {
  const { isMobile } = useEnvironment();
  const {
    isOpen,
    title,
    onClose,
    submitButtonProps,
    cancelButtonProps,
    ...rest
  } = props;

  useScrollBlock(isOpen, props.enableScrollLock);

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      timeout={300}
      in={isOpen}
      classNames={classes}
    >
      <Dialog
        isOpen
        forceBWTheme
        wiredToSiteColors={false}
        onClose={onClose}
        childrenWrapperClassName={classes.dialogContent}
        contentClassName={classes.contentWrapper}
        className={st(classes.root, { mobile: isMobile }, props.className)}
        {...rest}
      >
        <div className={classes.body}>
          {isMobile ? (
            <div className={classes.header}>
              <BlackAndWhiteTextButton
                aria-label={cancelButtonProps?.label}
                priority={TextButtonPriority.secondary}
                {...cancelButtonProps}
              >
                {cancelButtonProps?.label}
              </BlackAndWhiteTextButton>
              <Text
                tagName="h2"
                className={classes.title}
                typography={TextTypography.largeTitle}
              >
                {title}
              </Text>
              <BlackAndWhiteTextButton
                priority={TextButtonPriority.primary}
                onClick={submitButtonProps?.onClick}
                aria-label={submitButtonProps?.label}
                disabled={
                  submitButtonProps?.disabled || submitButtonProps?.loading
                }
                {...submitButtonProps}
              >
                {submitButtonProps?.loading ? (
                  <Spinner diameter={20} />
                ) : (
                  submitButtonProps?.label
                )}
              </BlackAndWhiteTextButton>
            </div>
          ) : (
            <div className={classes.header}>
              <Text
                tagName="h2"
                className={classes.title}
                typography={TextTypography.largeTitle}
              >
                {title}
              </Text>
            </div>
          )}
          <div className={classes.content}>{props.children}</div>
          {shouldRenderFooter() && (
            <div className={classes.footer}>
              <Button
                bw
                aria-label={cancelButtonProps?.label}
                priority={ButtonPriority.secondary}
                {...cancelButtonProps}
              >
                {cancelButtonProps?.label}
              </Button>
              <Button
                bw
                priority={ButtonPriority.primary}
                aria-label={submitButtonProps?.label}
                onClick={submitButtonProps?.onClick}
                disabled={
                  submitButtonProps?.disabled || submitButtonProps?.loading
                }
                {...submitButtonProps}
              >
                {submitButtonProps?.loading ? (
                  <Spinner diameter={20} />
                ) : (
                  submitButtonProps?.label
                )}
              </Button>
            </div>
          )}
        </div>
      </Dialog>
    </CSSTransition>
  );

  function shouldRenderFooter() {
    return !isMobile && !submitButtonProps?.hidden;
  }
};

SideDrawer.defaultProps = {
  enableScrollLock: true,
};

SideDrawer.displayName = 'SideDrawer';
