import { createEntityAdapter } from '@reduxjs/toolkit';
import { IJoinRequest } from 'api/groups/types';

import type { IRootState } from '../types';

import type { IGroupMember } from './types';

export const membersAdapter = createEntityAdapter<IGroupMember>({
  selectId: (member) => member.siteMemberId as string,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

export const joinRequestsAdapter = createEntityAdapter<IJoinRequest>({
  selectId: (request) => request.profile.siteMemberId as string,
});

export const selectors = membersAdapter.getSelectors<IRootState>(
  (state) => state.members,
);
