import React from 'react';
import { ErrorMonitorBoundary, useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';

import { Type } from '@wix/ambassador-social-groups-v2-group/types';

import {
  selectGroupIdBySlug,
  selectGroupRestriction,
  selectIsAppAvailable,
} from 'store/groups/selectors';
import { GroupAppKey } from 'store/groups/types';

import { useRouter, useCurrentStateAndParams } from 'common/router';

import { EmptyState } from 'common/components/EmptyState';

import { ErrorState } from 'Group/Widget/ErrorState';
import { GroupMembershipButton } from 'Group/Widget/GroupMembership';

import { classes } from './ProtectedPage.st.css';

interface IProtectedPageProps {
  application: GroupAppKey;
  children: React.ReactNode;
}

export function ProtectedPage(props: IProtectedPageProps) {
  const { application, children } = props;

  const { t } = useTranslation();
  const router = useRouter();
  const { params } = useCurrentStateAndParams();

  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const restriction = useSelector(selectGroupRestriction(groupId));
  const isAppAvailable = useSelector(
    selectIsAppAvailable({
      groupId,
      application,
    }),
  );

  if (isAppAvailable) {
    return (
      <ErrorMonitorBoundary fallback={<ErrorState onRetry={handleRetry} />}>
        {children}
      </ErrorMonitorBoundary>
    );
  }

  switch (restriction) {
    case Type.EVENTS:
    case Type.PAID_PLANS:
      return (
        <EmptyState
          className={classes.emptyState}
          dataHook="restricted-join"
          title={t('groups-web.restriction.join')}
          content={t('groups-web.restriction.join.subtitle')}
          button={
            <GroupMembershipButton
              groupId={groupId}
              biOrigin="group_feed_inside_page_btn"
            />
          }
        />
      );

    case Type.UNKNOWN:
      return <ErrorState onRetry={handleRetry} />;

    case Type.ADMIN_APPROVAL:
    default:
      return (
        <EmptyState
          className={classes.emptyState}
          dataHook="request-to-join"
          title={t('groups-web.group.private')}
          content={t('groups-web.join.private')}
          button={
            <GroupMembershipButton
              groupId={groupId}
              biOrigin="group_feed_inside_page_btn"
            />
          }
        />
      );
  }

  function handleRetry() {
    router.reload();
  }
}
