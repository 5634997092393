import React from 'react';
import { Button, ButtonProps } from 'wix-ui-tpa';

import { st, classes } from './BlackAndWhiteButton.st.css';

export const BlackAndWhiteButton: React.FC<ButtonProps> = ({
  className,
  ...restProps
}) => (
  <Button upgrade {...restProps} className={st(classes.root, {}, className)} />
);
