import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import {
  TransitionGroup,
  SwitchTransition,
  CSSTransition,
} from 'react-transition-group';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';

import { AvatarSize } from 'wix-ui-tpa';

import {
  selectSuggestedMembers,
  selectSuggestedMembersMetadata,
} from 'store/members/selectors';

import { useController } from 'common/context/controller';
import { UserCard } from 'common/components/Cards/UserCard';
import { MemberBadges } from 'common/components/MemberBadges';
import { EmptyState } from 'common/components/EmptyState';

import { IControllerMethods } from 'Group/types';

import { ErrorState } from 'Group/Widget/ErrorState';
import { settingsParams } from 'Group/Settings/settingsParams';

import { st, classes } from './SuggestedMembersList.st.css';

interface ISuggestedMembersProps {
  groupId: string;
  isSecret: boolean;
  selected: string[];
  search?: string;

  onSelect(memberIds: string[]): void;
}

export function SuggestedMembersList(props: ISuggestedMembersProps) {
  const { selected, search, groupId, isSecret, onSelect } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const settings = useSettings();

  const { members$ } = useController<IControllerMethods>();

  const meta = useSelector(selectSuggestedMembersMetadata);
  const members = useSelector(selectSuggestedMembers);

  const showBadges = settings.get(settingsParams.showMembersBadges);

  useEffect(() => {
    onSelect([]);
    members$.querySuggested(groupId, {
      nickname: search,
    });
  }, [search, groupId]);

  return (
    <SwitchTransition>
      {(() => {
        if (meta.error) {
          return (
            <CSSTransition timeout={300} key="error" classNames={classes}>
              <ErrorState
                transparent
                onRetry={handleRetry}
                boxProps={{
                  withTopBottomBorders: false,
                  withSideBorders: false,
                }}
              />
            </CSSTransition>
          );
        }

        if (meta.loading) {
          return (
            <CSSTransition timeout={300} key="loading" classNames={classes}>
              <Skeleton />
            </CSSTransition>
          );
        }

        if (!members.length) {
          return (
            <CSSTransition timeout={300} key="empty-state" classNames={classes}>
              <EmptyState
                transparent
                className={classes.emptyState}
                title={search ? undefined : t('group-web.members.empty')}
                content={t(getEmptyStateKey(isSecret, search))}
                boxProps={{
                  withTopBottomBorders: false,
                  withSideBorders: false,
                }}
              />
            </CSSTransition>
          );
        }

        return (
          <CSSTransition timeout={300} key="members-list" classNames={classes}>
            <InfiniteScroll
              initialLoad={false}
              useWindow={false}
              hasMore={meta.metadata.total! > members.length}
              loadMore={handleLoadMore}
              threshold={200}
              loader={
                <>
                  <UserCard loading divider avatarSize={AvatarSize.medium} />
                  <UserCard loading avatarSize={AvatarSize.medium} />
                </>
              }
            >
              <TransitionGroup>
                {members.map((member, index) => (
                  <CSSTransition
                    timeout={300}
                    key={member.siteMemberId}
                    classNames={classes}
                  >
                    <UserCard
                      bw
                      selectable
                      showBadges={false}
                      profile={member}
                      divider={index !== meta.metadata.total! - 1}
                      subtitle={
                        showBadges ? (
                          <MemberBadges badges={member.badges} />
                        ) : null
                      }
                      avatarSize={AvatarSize.medium}
                      selected={isMemberSelected(member.memberId as string)}
                      onSelect={handleSelect(member.memberId as string)}
                    />
                  </CSSTransition>
                ))}
              </TransitionGroup>
            </InfiniteScroll>
          </CSSTransition>
        );
      })()}
    </SwitchTransition>
  );

  function handleSelect(memberId: string) {
    return function (selected: boolean) {
      if (selected) {
        onSelect([...props.selected, memberId]);
      } else {
        onSelect(props.selected.filter((id) => id !== memberId));
      }
    };
  }

  function isMemberSelected(memberId: string) {
    return selected.includes(memberId);
  }

  function handleLoadMore() {
    members$.querySuggested(
      groupId,
      {
        nickname: search,
      },
      members.length,
    );
  }

  function handleRetry() {
    members$.querySuggested(groupId, {
      nickname: search,
    });
  }
}

function getEmptyStateKey(isSecret: boolean, search?: string) {
  if (isSecret) {
    return search
      ? 'groups-web.secret-group.members.empty-search-match.caption'
      : 'groups-web.discussion.members.empty';
  } else {
    return search
      ? 'group-web.members.empty-search-match.caption'
      : 'group-web.members.empty.caption';
  }
}

function Skeleton() {
  return (
    <ul className={classes.list}>
      <li>
        <UserCard divider loading avatarSize={AvatarSize.medium} />
      </li>
      <li>
        <UserCard divider loading avatarSize={AvatarSize.medium} />
      </li>
      <li>
        <UserCard loading avatarSize={AvatarSize.medium} />
      </li>
    </ul>
  );
}
