import React from 'react';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Text, Dialog, DialogProps } from 'wix-ui-tpa';

import { useScrollBlock } from '../../hooks';

import { classes, st } from './ModalV2.st.css';

export interface ModalV2FooterProps {
  okButton: React.ReactNode;
  cancelButton?: React.ReactNode;
  sideAction?: React.ReactNode;
  className?: string;
}

type IModal<P> = React.FC<P> & {
  Content: React.FC<{
    className?: string;
  }>;
  Title: React.FC;
  Subtitle: React.FC;
  Footer: React.FC<ModalV2FooterProps>;
};

export const ModalV2: IModal<DialogProps> = (props) => {
  const { isOpen, ...rest } = props;
  const { isMobile } = useEnvironment();

  useScrollBlock(isOpen);

  return (
    <CSSTransition
      mountOnEnter
      unmountOnExit
      timeout={300}
      in={isOpen}
      classNames={classes}
    >
      <Dialog
        {...rest}
        isOpen
        forceBWTheme
        wiredToSiteColors={false}
        className={classes.root}
        childrenWrapperClassName={classes.dialogContent}
        contentClassName={st(
          classes.contentWrapper,
          { mobile: isMobile },
          props.className,
        )}
      />
    </CSSTransition>
  );
};

ModalV2.displayName = 'ModalV2';
ModalV2.Title = ({ children }) => (
  <Text className={classes.title}>{children}</Text>
);
ModalV2.Subtitle = ({ children }) => (
  <Text className={classes.subtitle}>{children}</Text>
);
ModalV2.Content = ({ children, className }) => (
  <div
    className={classNames(classes.content, className)}
    data-hook="ModalV2.Content"
  >
    {children}
  </div>
);
ModalV2.Footer = ({ okButton, cancelButton, sideAction, className }) => (
  <div className={classNames(classes.footer, className)}>
    <div>{sideAction ? sideAction : null}</div>
    <div className={classes.buttons}>
      <div className={classes.cancelButtonWrapper}>{cancelButton}</div>
      {okButton}
    </div>
  </div>
);
ModalV2.displayName = 'ModalV2';
ModalV2.Title.displayName = 'ModalV2.Title';
ModalV2.Subtitle.displayName = 'ModalV2.Subtitle';
ModalV2.Content.displayName = 'ModalV2.Content';
ModalV2.Footer.displayName = 'ModalV2.Footer';
