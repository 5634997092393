import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import {
  selectHasAdminRole,
  selectGroup,
  selectIsGroupUpdating,
} from 'store/groups';
import {
  ImageFocalPoint,
  ImageLoadingBehaviorOptions,
  ImageResizeOptions,
  WowImage,
} from 'wix-ui-tpa';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { useFileUploader } from 'common/hooks';
import { useController } from 'common/context/controller';

import type { IControllerMethods } from 'Group/types';
import { CoverImageLayout } from 'Group/Settings/settingsConstants';
import { settingsParams } from 'Group/Settings/settingsParams';
import { stylesParams } from 'Group/Settings/styles';

import { LogoEditor } from './LogoEditor';

import { st, classes } from './CoverImage.st.css';

interface ICoverImageProps extends TPAComponentProps {
  groupId: string;
}

export function CoverImage(props: ICoverImageProps) {
  const { isMobile } = useEnvironment();
  const { t } = useTranslation();
  const settings = useSettings();
  const styles = useStyles();
  const isAdmin = useSelector(selectHasAdminRole(props.groupId));
  const group = useSelector(selectGroup(props.groupId));
  const isUpdating = useSelector(selectIsGroupUpdating(props.groupId));
  const { group$ } = useController<IControllerMethods>();
  const fileUploader = useFileUploader();

  const { coverImage } = group;
  const coverImageLayout = settings.get(settingsParams.coverImageLayout);
  const coverImageHeight = styles.get(stylesParams.coverImageHeight);
  const isLarge = coverImageLayout === CoverImageLayout.large;

  const targetWidth = useMemo(
    () => (isMobile ? 320 : isLarge ? 940 : 100),
    [isMobile, isLarge],
  );

  const targetHeight = useMemo(() => {
    if (isMobile) {
      return 240;
    }

    return isLarge ? coverImageHeight || 240 : 100;
  }, [isMobile, isLarge, coverImageHeight]);

  const [logo, setLogo] = useState<File>();
  const [isLoading, setIsLoading] = useState(false);
  const [imageSrc, setImageSrc] = useState(getImageSrc());
  const [focalPoint, setFocalPoint] = useState(getFocalPoint());

  useEffect(() => {
    setImageSrc(getImageSrc());
  }, [coverImage?.image?.mediaId, coverImage?.image?.fileUrl]);

  useEffect(() => {
    setFocalPoint(getFocalPoint());
  }, [
    isMobile,
    JSON.stringify(coverImage?.position),
    JSON.stringify(coverImage?.mobilePosition),
  ]);

  return (
    <div
      className={st(classes.root, {}, props.className)}
      data-hook="super-hero-image-root"
    >
      <WowImage
        key={imageSrc}
        fluid={isLarge}
        src={imageSrc}
        width={targetWidth}
        height={targetHeight}
        data-hook="group-image"
        focalPoint={focalPoint}
        resize={ImageResizeOptions.cover}
        loadingBehavior={ImageLoadingBehaviorOptions.blur}
        sourceWidth={group.coverImage?.image?.width as number}
        sourceHeight={group.coverImage?.image?.height as number}
      />
      {isAdmin && !isMobile && (
        <LogoEditor
          loading={isLoading || isUpdating}
          focalPoint={focalPoint}
          onLogoChange={handleLogoChange}
          onFocalPointChange={handleFocalPointChange}
          enabled={!!logo}
          onSave={handleSave}
          onCancel={handleCancel}
        />
      )}
    </div>
  );

  function handleCancel() {
    setLogo(undefined);
    setImageSrc(getImageSrc());
    setFocalPoint(getFocalPoint());
  }

  async function handleSave() {
    if (!logo) {
      return;
    }

    setIsLoading(true);

    const {
      height,
      width,
      file_name: mediaId,
    } = await fileUploader.upload(logo);

    setIsLoading(false);

    group$.updateGroupInfo(props.groupId, {
      coverImage: {
        image: {
          mediaId,
          height,
          width,
        },
        position: focalPoint,
      },
    });

    setLogo(undefined);
  }

  function handleFocalPointChange(focalPoint: ImageFocalPoint) {
    setFocalPoint(focalPoint);
  }

  function handleLogoChange(file: File) {
    imageSrc && URL.revokeObjectURL(imageSrc);
    setImageSrc(URL.createObjectURL(file));
    setFocalPoint({ x: 0, y: 50 });
    setLogo(file);
  }

  function getImageSrc() {
    return (coverImage?.image?.mediaId || coverImage?.image?.fileUrl) as string;
  }

  function getFocalPoint() {
    const focalPoint = !isMobile
      ? coverImage?.position
      : coverImage?.mobilePosition;

    return (focalPoint as ImageFocalPoint) || { x: 0, y: 50 };
  }
}
