import React from 'react';
import { useSelector } from 'react-redux';

import { Tabs, TabItem, TabsAlignment, TabsVariant } from 'wix-ui-tpa';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { groupsTabClicked } from '@wix/bi-logger-groups/v2';

import { useCurrentStateAndParams, useRouter } from 'common/router';

import { GroupAppKey } from 'store/groups/types';
import {
  selectInstalledApplications,
  selectGroupIdBySlug,
  selectGroupUpdates,
} from 'store/groups/selectors';

import { st, classes } from './GroupTabs.st.css';

export function GroupTabs() {
  const router = useRouter();
  const { state, params } = useCurrentStateAndParams();
  const { isRTL, isMobile, isSSR } = useEnvironment();
  const { t } = useTranslation();
  const bi = useBi();

  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const groupUpdates = useSelector(selectGroupUpdates(groupId));
  const applications = useSelector(selectInstalledApplications(groupId));

  const tabs = React.useMemo<TabItem[]>(
    () =>
      applications.map((application) => {
        const route = getApplicationRoute(application.key!);
        const counter = route?.data?.counter as keyof typeof groupUpdates;
        const updates = groupUpdates[counter];

        return {
          id: application.key,
          title: application.customName || t(route?.data?.title),
          href: isSSR
            ? router.href(route!.name as string, params, { absolute: true })
            : undefined,
        };
      }),
    [
      isSSR,
      JSON.stringify(params),
      JSON.stringify(groupUpdates),
      applications
        .map(
          (application) =>
            `${application.key}:${application.installed}:${application.customName}`,
        )
        .toString(),
    ],
  );

  const activeTab = tabs.findIndex((tab) => tab.id === state.data?.application);

  return (
    <div className={st(classes.root, { mobile: isMobile })}>
      <Tabs
        data-hook="tabsHook"
        className={classes.tabs}
        items={tabs}
        activeTabIndex={activeTab}
        alignment={isRTL ? TabsAlignment.right : TabsAlignment.left}
        variant={isMobile ? TabsVariant.fullWidth : TabsVariant.fit}
        onTabClick={handleTabClick}
      />
    </div>
  );

  function getApplicationRoute(key: GroupAppKey) {
    return Object.values(router.states).find(
      (route) => route.data?.application === key,
    );
  }

  function handleTabClick(index: number) {
    const tab = tabs[index];
    const route = getApplicationRoute(tab.id as GroupAppKey);

    router.go(route!.name as string);

    bi.report(
      groupsTabClicked({
        group_id: groupId,
        origin: 'tabs',
        name: tab.id,
      }),
    );
  }
}
