import { curryRight } from 'lodash';
import { createSelector } from 'reselect';

import type { IRootState } from '../types';

import { selectors, membersAdapter, joinRequestsAdapter } from './adapter';

export const selectMembers = (state: IRootState) => selectors.selectAll(state);

export const selectChangeRoleStatus = curryRight(
  createSelector(
    [
      (state: IRootState) => state.members.status.changeRole,
      (state: IRootState, memberId: string) => memberId,
    ],
    (status, memberId) => status[memberId] || {},
  ),
  2,
);

export const selectJoinRequestStatus = curryRight(
  createSelector(
    [
      (state: IRootState) => state.members.requests.status,
      (state: IRootState, memberId: string) => memberId,
    ],
    (status, memberId) => ({
      approve: status.approve[memberId] || {},
      reject: status.reject[memberId] || {},
    }),
  ),
  2,
);

export const selectJoinRequests = (state: IRootState) => ({
  metadata: state.members.requests.metadata,
  requests: joinRequestsAdapter
    .getSelectors()
    .selectAll(state.members.requests),
});

export const selectRemoveStatus = curryRight(
  createSelector(
    [
      (state: IRootState) => state.members.status.remove,
      (state: IRootState, memberId: string) => memberId,
    ],
    (status, memberId) => status[memberId] || {},
  ),
  2,
);

export const selectAnswersStatus = curryRight(
  createSelector(
    [
      (state: IRootState) => state.members.status.answers,
      (state: IRootState, memberId: string) => memberId,
    ],
    (status, memberId) => status[memberId] || {},
  ),
  2,
);

export const selectMembersMetadata = (state: IRootState) => ({
  metadata: state.members.metadata,
  loading: state.members.status.fetch.loading,
  error: state.members.status.fetch.error,
});

export const selectSuggestedMembers = (state: IRootState) =>
  membersAdapter.getSelectors().selectAll(state.members.suggested);

export const selectSuggestedMembersMetadata = (state: IRootState) => ({
  metadata: state.members.suggested.metadata,
  loading: state.members.suggested.status.fetch.loading,
  error: state.members.suggested.status.fetch.error,
});

export const selectInviteStatus = curryRight(
  createSelector(
    [
      (state: IRootState) => state.members.suggested.status.invite,
      (state: IRootState, memberId: string) => memberId,
    ],
    (status, memberId) => status[memberId] || {},
  ),
  2,
);

export const selectInviteStatuses = (state: IRootState) =>
  state.members.suggested.status.invite;
