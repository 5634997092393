import React, { useState } from 'react';
import { ControllerContext } from './ControllerContext';

interface IControllerProviderProps {
  children: React.ReactNode;
  controller: any;
}

export function ControllerProvider(props: IControllerProviderProps) {
  const { children, controller } = props;

  const [value] = useState(controller);

  return (
    <ControllerContext.Provider value={value}>
      {children}
    </ControllerContext.Provider>
  );
}

export function useController<T>() {
  return React.useContext(ControllerContext) as T;
}
