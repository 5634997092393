import React from 'react';
import { Text, TextTypography, ButtonPriority } from 'wix-ui-tpa';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';

import { UISref } from 'common/router';
import { BlockAlign, BlockFlow, Block } from 'common/components/Block';
import { Container } from 'common/components/Container/Container';
import { Button } from 'common/components/Button';

import { st, classes } from './GroupErrorStates.st.css';

export function GroupRejected() {
  const { t } = useTranslation();
  const { dimensions } = useEnvironment();

  return (
    <Container
      className={st(classes.root, {})}
      style={{ height: dimensions.height }}
    >
      <Block
        flow={BlockFlow.row}
        place={BlockAlign.center}
        align={BlockAlign.center}
        className={classes.block}
      >
        <Text
          tagName="h2"
          typography={TextTypography.largeTitle}
          className={classes.title}
        >
          {t('groups-web.rejected.title')}
        </Text>
        <Text>{t('groups-web.rejected.caption')}</Text>
        <Block place={BlockAlign.center}>
          <UISref state="groups">
            <Button
              as="a"
              priority={ButtonPriority.primary}
              className={classes.action}
            >
              {t('groups-web.rejected.action')}
            </Button>
          </UISref>
        </Block>
      </Block>
    </Container>
  );
}

GroupRejected.displayName = 'GroupRejected';
