import React from 'react';

import { useSelector } from 'react-redux';
import { IconButton, IconButtonSkins } from 'wix-ui-tpa';
import ShareIcon from 'wix-ui-icons-common/on-stage/Share';
import { useSettings } from '@wix/tpa-settings/react';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { groupActionClick } from '@wix/bi-logger-groups/v2';

import { selectGroupIdBySlug, selectIsGroupSecret } from 'store/groups';
import { useCurrentStateAndParams, useHref, UISref } from 'common/router';
import { ShareModal } from 'common/components/ShareModal/ShareModal';

import { CoverImageLayout } from '../../Settings/settingsConstants';
import { settingsParams } from '../../Settings/settingsParams';
import { GroupMembershipButton } from '../GroupMembership';
import { ArrowBackIcon } from '../icons/ArrowBackIcon';

import { Details } from './Details/Details';
import { GroupActions } from './GroupActions/GroupActions';
import { InviteMembers } from './InviteMembers/InviteMembers';
import { CoverImage } from './CoverImage';

import { GROUP_HEADER } from '../dataHooks';

import { classes, st } from './Header.st.css';

export const Header: React.FC = () => {
  const { params } = useCurrentStateAndParams();
  const [isShareModalOpen, setIsShareModalOpen] = React.useState(false);
  const environment = useEnvironment();
  const { t } = useTranslation();
  const settings = useSettings();
  const bi = useBi();

  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const isGroupSecret = useSelector(selectIsGroupSecret(groupId));
  const coverImageLayout = settings.get(settingsParams.coverImageLayout);
  const withImage = coverImageLayout !== CoverImageLayout.blank;

  const absoluteGroupShareUrl = useHref(
    'group',
    { slug: params.slug },
    { absolute: true },
  );

  return (
    <div
      className={st(classes.root, {
        withImage,
        mobile: environment.isMobile,
        coverImageLayout,
      })}
      data-hook={GROUP_HEADER}
    >
      <div className={classes.image}>
        {withImage && (
          <CoverImage groupId={groupId} className={classes.largeImage} />
        )}
      </div>

      {environment.isMobile && (
        <div className={classes.mobileActionsHolder}>
          {withImage && <div className={classes.buttonsOverlayOnImage} />}

          <div className={classes.mobileActions}>
            <div className={classes.mobileActionsContainer}>
              <div className={classes.leftButtonsBlock}>
                <UISref state="groups">
                  <IconButton
                    as={environment.isViewer ? 'a' : undefined}
                    data-hook="arrow-back-button"
                    skin={IconButtonSkins.Full}
                    icon={<ArrowBackIcon width="24px" height="24px" />}
                    className={classes.arrowBackIcon}
                  />
                </UISref>
              </div>
              <div className={classes.rightButtonsBlock}>
                {renderShareButton()}
                {renderGroupActions(classes.groupActionsButtonMobile)}
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={classes.groupInfo}>
        <Details groupId={groupId} />
      </div>

      <div className={classes.groupActions}>
        <GroupMembershipButton
          groupId={groupId}
          className={classes.membershipButton}
          biOrigin="group_feed_top_banner_btn"
        />
        <InviteMembers groupId={groupId} />
        {!environment.isMobile && renderShareButton()}
        {!environment.isMobile && renderGroupActions(classes.threeDotsIcon)}
      </div>

      <ShareModal
        isOpen={isShareModalOpen}
        onClose={closeShareModal}
        title={t('groups-web.group.actions.share.group')}
        shareUrl={absoluteGroupShareUrl}
      />
    </div>
  );

  function renderShareButton() {
    if (isGroupSecret) {
      return null;
    }

    return (
      <IconButton
        aria-label="Share"
        data-hook="share-button"
        skin={IconButtonSkins.Full}
        icon={<ShareIcon width="24px" height="24px" />}
        onClick={() => openShareModal('arrow')}
        className={
          environment.isMobile ? classes.shareButtonMobile : classes.shareButton
        }
      />
    );
  }

  function renderGroupActions(iconClassName?: string) {
    return (
      <GroupActions
        groupId={groupId}
        onShare={() => openShareModal('menu')}
        iconClassName={iconClassName}
      />
    );
  }

  function closeShareModal() {
    setIsShareModalOpen(false);
  }

  function openShareModal(biOrigin: string) {
    setIsShareModalOpen(true);

    bi.report(
      groupActionClick({
        action: 'share',
        group_id: groupId,
        origin: biOrigin,
      }),
    );
  }
};

Header.displayName = 'Header';
