import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';

import { Dialog } from 'common/components/Dialog';
import { useController } from 'common/context/controller';
import { useRouter } from 'common/router';
import { getLocaleDateString } from 'common/utils/date';

import { selectFuturePlans } from 'store/groups/selectors';
import { selectDialog } from 'store/application/selectors';

import { IControllerMethods } from 'Group/types';

import { usePricingPlansQueryParams } from './usePricingPlansQueryParams';
import { usePaidPlansBi } from './bi';

export const FuturePlanDialog: React.FC = () => {
  const { t } = useTranslation();

  const { application$ } = useController<IControllerMethods>();

  const router = useRouter();

  const bi = usePaidPlansBi();

  const dialog = useSelector(selectDialog('futurePlanDialog'));
  const futurePlans = useSelector(selectFuturePlans(dialog.groupId as string));

  const queryParams = usePricingPlansQueryParams(dialog.groupId as string);

  if (!futurePlans.length) {
    return null;
  }

  const date = getLocaleDateString(new Date(`${futurePlans[0].startsAt}`));

  return (
    <Dialog
      isOpen={dialog.isOpen}
      onClose={handleClose}
      title={t('groups-web.restriction.plans.future.dialog.title')}
      caption={t('groups-web.restriction.plans.future.dialog.subtitle', {
        date,
      })}
      okLabel={t('groups-web.restriction.plans.future.dialog.cta')}
      cancelLabel={t('groups-web.restriction.plans.future.dialog.cancel')}
      onOkClick={() => {
        bi.getAnotherPlanClick();
        router.go('pricing-plans', {
          appSectionParams: queryParams,
        });
      }}
      onCancelClick={handleClose}
    />
  );

  function handleClose() {
    application$.closeDialog('futurePlanDialog');
  }
};
