import React from 'react';
import { pick } from 'lodash';
import type { WidgetProps } from '@wix/yoshi-flow-editor';
import { WixCommentsApiProvider } from '@wix/comments-ooi-client';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import { SocialGroupStore } from 'store/SocialGroupsStore';

import { ThemeProvider } from 'common/context/theme/ThemeContext';
import { ControllerProvider } from 'common/context/controller';
import { Router } from 'common/router';
import { Toasts } from 'common/components/Toasts';

import type { IControllerProps } from 'Group/types';

import { settingsParams } from 'Group/Settings/settingsParams';
import { stylesParams } from 'Group/Settings/styles';

import { Group } from './Group/Group';

function Widget(props: WidgetProps<IControllerProps>) {
  const settings = useSettings();
  const styles = useStyles();

  const methods = pick(
    props,
    'application$',
    'feed$',
    'topics$',
    'group$',
    'media$',
    'members$',
    'events$',
    'about$',
    'router$',
  );

  return (
    <SocialGroupStore state={props.store}>
      <ControllerProvider controller={methods}>
        <Toasts />
        <Router>
          <WixCommentsApiProvider {...props}>
            <ThemeProvider
              host={props.host}
              buttonType={settings.get(settingsParams.buttonType)}
              buttonBorderRadius={styles.get(stylesParams.buttonBorderRadius)}
            >
              <Group />
            </ThemeProvider>
          </WixCommentsApiProvider>
        </Router>
      </ControllerProvider>
    </SocialGroupStore>
  );
}

export default Widget;
