import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { useCurrentStateAndParams } from 'common/router';

import { DiscussionPage } from '../../DiscussionPage/loadable';
import { MediaPage } from '../../MediaPage/loadable';
import { AboutPage } from '../../AboutPage/loadable';
import { EventsPage } from '../../EventsPage/loadable';
import { MembersPage } from '../../MembersPage/loadable';
import { CustomTabPage } from '../../CustomTabPage/loadable';

import { ProtectedPage } from './ProtectedPage';

import { classes, st } from './ApplicationPage.st.css';

const routes = [
  {
    state: 'group.discussion',
    component: DiscussionPage,
  },
  {
    state: 'group.media',
    component: MediaPage,
  },
  {
    state: 'group.about',
    component: AboutPage,
  },
  {
    state: 'group.events',
    component: EventsPage,
  },
  {
    state: 'group.members',
    component: MembersPage,
  },
  {
    state: 'group.custom-tab',
    component: CustomTabPage,
  },
  {
    state: 'group.file-tab',
    component: CustomTabPage,
  },
];

export function ApplicationPage() {
  const { state } = useCurrentStateAndParams();
  const { isMobile } = useEnvironment();

  const active = routes.find((item) => state.name?.includes(item.state));
  const Page = active?.component || null;

  return (
    <SwitchTransition>
      <CSSTransition key={state.name} timeout={300} classNames={classes}>
        <section
          aria-labelledby={state.data?.application}
          className={st(classes.root, {
            mobile: isMobile,
            withSideGutters: true,
          })}
        >
          <ProtectedPage application={state.data?.application}>
            {Page && <Page />}
          </ProtectedPage>
        </section>
      </CSSTransition>
    </SwitchTransition>
  );
}
