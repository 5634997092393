import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Text } from 'wix-ui-tpa';

import { selectToasts } from 'store/application/toasts';
import { useController } from 'common/context/controller';
import type { IControllerMethods } from 'Group/types';

import { TOAST_MESSAGE } from './dataHook';

import './Toasts.global.css';
import { st, classes } from './Toasts.st.css';

export function Toasts() {
  const { isMobile, isRTL, isSSR } = useEnvironment();

  const { application$ } = useController<IControllerMethods>();
  const toasts = useSelector(selectToasts);

  useEffect(() => {
    if (isSSR) {
      return;
    }

    toasts.forEach((_toast) => {
      toast(
        <>
          <Text data-hook={TOAST_MESSAGE} className={classes.text}>
            {_toast.message}
          </Text>
          {_toast.description && (
            <>
              <br />
              <Text className={classes.description}>{_toast.description}</Text>
            </>
          )}
        </>,
        {
          toastId: _toast.id,
          autoClose: _toast.timeout || 10000,
          type: _toast.type,
          onClose: handleToastClose(_toast.id),
        },
      );
    });
  }, [toasts.map((toast) => toast.id).toString(), isSSR]);

  return (
    <ToastContainer
      pauseOnHover
      closeOnClick
      hideProgressBar
      theme="colored"
      rtl={isRTL}
      autoClose={5000}
      closeButton={false}
      draggable={isMobile}
      pauseOnFocusLoss={false}
      limit={isMobile ? 2 : 5}
      className={st(classes.root)}
      position={isMobile ? 'bottom-center' : 'top-center'}
    />
  );

  function handleToastClose(toastId: string) {
    return () => {
      application$.closeToast(toastId);
    };
  }
}
